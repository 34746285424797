<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    class="rotate-180"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6.42857V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V17"
      :stroke="getBaseColor(darkMode)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7071 8.79289C13.3166 8.40237 12.6834 8.40237 12.2929 8.79289C11.9024 9.18342 11.9024 9.81658 12.2929 10.2071L13.0858 11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H13.0858L12.2929 13.7929C11.9024 14.1834 11.9024 14.8166 12.2929 15.2071C12.6834 15.5976 13.3166 15.5976 13.7071 15.2071L16.2071 12.7071C16.5976 12.3166 16.5976 11.6834 16.2071 11.2929L13.7071 8.79289Z"
      :fill="accent"
    />
  </svg>
</template>
